@font-face {
  font-family: 'quattrocento-normal';
  font-style:  regular;
  font-display: swap;
  src: url("Quattrocento-Regular.ttf");
       
}
/* @font-face {
  font-family: 'quattrocento';
  font-style:  bold;
  font-weight: 100;
  font-display: swap;
  src: url("Quattrocento-Bold.ttf");
 
} */
